<template>
  <div>
    <material-card
      color="general"
      :title="$t('User.title')"
      :text="$t('User.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("Common.new_item") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-account-check
            </v-icon>
            {{ $t("User.subtitle") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form 
              ref="editUserForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-text-field
                      v-model="editedItem.firstName"
                      :rules="[rules.required]"
                      :label="$t('Common.first_name')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-text-field
                      v-model="editedItem.lastName"
                      :rules="[rules.required]"
                      :label="$t('Common.last_name')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-text-field
                      v-model="editedItem.email"
                      :rules="[rules.required, rules.email]"
                      :disabled="editedIndex > 0"
                      :label="$t('Common.email')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-text-field
                      v-model="editedItem.phone"
                      :label="$t('Common.phone_number')"
                      :rules="[rules.phoneNumber]"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-text-field
                      v-model="editedItem.password"
                      :label="$t('Common.password')"
                      type="password"
                      :rules="[rules.minLength6]"
                      counter
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-select
                      v-model="editedItem.role"
                      :items="sortedRoles"
                      menu-props="auto"
                      :label="$t('Common.role')"
                      :rules="[rules.required]"
                    />
                  </v-flex>
                  <v-flex v-if="role === 'SUPER_ADMIN'">
                    <v-select
                      v-model="editedItem.company_id"
                      :items="sortedCompanyList"
                      item-text="company_name"
                      item-value="id"
                      menu-props="auto"
                      :label="$t('Common.company')"
                      @change="changeEditedItemCompany"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
              <v-alert
                v-if="alertTextEdit"
                dense
                type="error"
              >
                {{ alertTextEdit }}
              </v-alert>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :disabled="!editedItem.isValid"
              :loading="isSaving"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-text-field
          v-model="paginationOptions.search"
          append-icon="mdi-magnify"
          :label="$t('Common.search')"
          single-line
          hide-details
          solo
          class="z-search mb-4"
        />
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="userList ? userList : []"
          :options.sync="paginationOptions"
          :server-items-length="(userListMeta?.itemCount) ? userListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              class="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultPopup from "../../components/DefaultPopup.vue";
import validationMixin from "../../components/helper/validationMixin";

export default {
  components: {
    DefaultPopup,
  },

  mixins: [validationMixin],

  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      role: "",
      company_id: "",
      isValid: true,
      loggedUserRole:"",
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      role: "",
      company_id: "",
      loggedUserRole: ","
    },
    role: "",
    alertTextEdit: "",
    alertTextDelete: "",
    isSaving: false,
    sortedRoles: null,
    sortedCompanyList: null,
    // table:
    paginationLoading: false,
    paginationOptions: {},
    oldSearchValue: '',
  }),

  computed: {
    ...mapGetters("company", ["companyList"]),
    ...mapGetters("user", ["userList", "userListMeta", "roles"]),

    testUserList() {
      return this.$store.getters['userList'];
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      return [
        { text: this.$t("Common.first_name"), value: "firstName" },
        { text: this.$t("Common.last_name"), value: "lastName" },
        { text: this.$t("Common.email"), value: "email" },
        { text: this.$t("Common.phone_number"), value: "phone" },
        { text: this.$t("Common.role"), value: "role" },
        { text: this.$t("Common.company"), value: "company.company_name", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = "";
      if (val) this.$nextTick(() => {this.$refs.editUserForm.validate()});
      else this.close();
    },
    paginationOptions: {
      handler(val) {
        if(val.search !== this.oldSearchValue) this.paginationOptions.page = 1
        this.oldSearchValue = val.search;
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  beforeDestroy() {
    this.clearUserState();
    this.clearCompanyState();
  },

  methods: {
    ...mapActions("user", [
      "getUserList",
      "deleteUser",
      "createUser",
      "getRoles",
      "updateUser",
      "clearUserState",
    ]),
    ...mapActions("company", ["getCompanyList", "clearCompanyState"]),

    async initialize() {
      this.paginationLoading = true;
      this.role = sessionStorage.getItem("role");
      this.paginationOptions.role = this.role;
      if (this.role === "SUPER_ADMIN") {
        await Promise.all([
          this.getCompanyList({ itemsPerPage: 'all', role: this.role }),
          this.getRoles(),
          this.getUserList(this.paginationOptions),
        ]);
        this.sortCompanyList();
      } else {
        await Promise.all([
          this.getRoles(),
          this.getUserList(this.paginationOptions),
        ]);
      }
      this.sortRoles();
      this.paginationLoading = false;
    },

    sortRoles() {
      this.sortedRoles = this.roles?.sort();
    },

    sortCompanyList() {
      this.sortedCompanyList = this.companyList.sort((a, b) =>
        a.company_name.localeCompare(b.company_name)
      );
    },

    editItem(item) {
      this.editedIndex = this.userList.indexOf(item);
      this.editedItem = Object.assign({}, item, { company_id: item.company.id});
      this.dialog = true;
    },

    changeEditedItemCompany(id) {
      this.editedItem.company = this.sortedCompanyList.find(c => c.id === id)
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteUser(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("User.alerts.error-delete");
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async save() {
      this.isSaving = true;
      this.alertTextEdit = "";
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateUser(this.editedItem);
        if (isUpdated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("User.alerts.error-edit");
        }
      } else {
        this.editedItem.loggedUserRole = sessionStorage.getItem("role");
        const isUpdated = await this.createUser(this.editedItem);
        if (isUpdated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("User.alerts.error-create");
        }
      }
      this.isSaving = false;
    },
  },
};
</script>
